import { useSearchParams } from 'next/navigation';

export default function useQuerySearch(
  params: string[] = []
): Record<string, string | null> {
  const searchParams = useSearchParams();

  const querySearch: Record<string, string | null> = {};

  if (params.length === 0) {
    return {};
  }

  params.forEach((param) => {
    querySearch[param] = searchParams?.get(param) || null;
  });

  return querySearch;
}
