import React from 'react';

import { CommonConstant } from '@/helpers/common.constant';
import { setCookie } from '@/helpers/cookieStorage';
import { setLocalStorage } from '@/helpers/localStorage';

export const onAuthentication = ({
  refreshToken,
  accessToken,
}: {
  refreshToken: string;
  accessToken: string;
}) => {
  if (accessToken && refreshToken) {
    setLocalStorage(CommonConstant._ACCESS_TOKEN, accessToken);
    setLocalStorage(CommonConstant._REFRESH_TOKEN, refreshToken);

    setCookie(CommonConstant._ACCESS_TOKEN, accessToken, 24 * 60 * 60 * 1000);
  }
};

export default async function useAuthentication({
  refreshToken,
  accessToken,
}: any = {}) {
  React.useEffect(() => {
    onAuthentication({ refreshToken, accessToken });
  }, [accessToken, refreshToken]);

  return;
}
