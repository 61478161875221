import { onAuthentication } from '@/hooks/useAuthentication';

import { CommonConstant } from '@/helpers/common.constant';
import { deleteLocalStorage, getLocalStorage } from '@/helpers/localStorage';

import apiService from './api.service';
import endpointConfig from './endpoint.config';

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

export const apiInterceptor = () => {
  apiService.interceptors.request.use(
    async (config) => {
      const accessToken = getLocalStorage(CommonConstant._ACCESS_TOKEN);

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  apiService.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const { config } = error;

      if (
        error.response.status === 401 &&
        config.url === endpointConfig.refreshToken
      ) {
        clearAuthorization();

        return Promise.reject(error);
      }

      if (error.response.status === 401 && !config._retry) {
        if (isRefreshing) {
          try {
            const token = await new Promise((resolve, reject) => {
              failedQueue.push({ resolve, reject });
            });
            config.headers.Authorization = `Bearer ${token}`;
            return apiService(config);
          } catch (err) {
            return Promise.reject(err);
          }
        }

        config._retry = true;
        isRefreshing = true;

        try {
          const { accessToken, refreshToken } = await onRefreshToken();
          onAuthentication({
            accessToken,
            refreshToken,
          });
          apiService.defaults.headers.common['Authorization'] =
            `Bearer ${accessToken}`;
          processQueue(null, accessToken);
          return apiService(config);
        } catch (err) {
          processQueue(err, null);
          clearAuthorization();
          return Promise.reject(err);
        } finally {
          isRefreshing = false;
        }
      }

      return Promise.reject(error);
    }
  );
};

apiInterceptor();

const onRefreshToken = async () => {
  const refreshToken = getLocalStorage(CommonConstant._REFRESH_TOKEN);

  const { data } = await apiService.post(endpointConfig.refreshToken, {
    refreshToken,
  });

  return data;
};

const clearAuthorization = () => {
  apiService.defaults.headers.common.Authorization = null;
  deleteLocalStorage(CommonConstant._REFRESH_TOKEN);
  deleteLocalStorage(CommonConstant._ACCESS_TOKEN);
};
