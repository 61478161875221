import Link from 'next/link';
import React from 'react';

import { useAppSelector } from '@/store';

import { Routes } from '@/helpers/common.constant';

export const Logo = (
  { width, height }: { width?: number; height?: number } = {
    height: 48,
    width: 220,
  }
) => {
  const { auth } = useAppSelector((state) => state.auth);

  return (
    <Link href={!auth ? Routes._HOME : Routes._OVERVIEW}>
      {/* <Image src={SVG.LogoSVG} alt='Logo' width={width} height={height} /> */}

      <span className='text-nowrap text-lg font-medium text-primary xl:text-2xl'>
        ESGLance LOGO
      </span>
    </Link>
  );
};
