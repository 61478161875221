import React from 'react';

import { AuthSection } from './AuthSection';
import MenuMobile from './MenuMobile';
import MenuPC from './MenuPC';
import NavSearchSection from './NavSearchSection';
import { Logo } from '../Logo';

export const Header = () => {
  return (
    <header className='flex-center fixed top-0 z-[999] min-h-[71px] w-full border-b bg-white'>
      <div className='main-app'>
        <nav className='mx-auto flex w-full items-center justify-start gap-5 pl-2 pr-4 xl:pl-5 xl:pr-5 '>
          <MenuMobile />
          <Logo />
          <div className='grow'>
            <MenuPC />
          </div>
          <NavSearchSection />
          <AuthSection />
        </nav>
      </div>
    </header>
  );
};
