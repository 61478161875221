'use client';

const setLocalStorage = (name: string, value: string) => {
  localStorage.setItem(name, value);
};

const getLocalStorage = (name: string) => {
  return localStorage.getItem(name);
};

const deleteLocalStorage = (name: string) => {
  localStorage.removeItem(name);
};

export { deleteLocalStorage, getLocalStorage, setLocalStorage };
