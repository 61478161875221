import api from '@/services/api/api.service';
import endpointConfig from '@/services/api/endpoint.config';

import {
  ICheckValidToken,
  IRegister,
  IRegisterSocial,
  IResetPassword,
  ISignIn,
  ISignInSocial,
  IVerifyEmail,
} from '@/types/auth.type';

const signUp = async (data: IRegister) => {
  return await api.post(endpointConfig.signUp, data);
};

const signUpWithSocial = async (data: IRegisterSocial) => {
  return await api.post(endpointConfig.signUpSocial, data);
};

const signIn = async (data: ISignIn) => {
  return await api.post(endpointConfig.signIn, data);
};

const signInWithSocial = async (data: ISignInSocial) => {
  return await api.post(endpointConfig.signInSocial, data);
};

const verifyEmail = async (data: IVerifyEmail) => {
  return await api.post(endpointConfig.verifyEmail, data);
};

const forgotPassword = async ({ email }: { email: string }) => {
  return await api.post(endpointConfig.forgotPassword, { email });
};

const resetPassword = async (data: IResetPassword) => {
  return await api.post(endpointConfig.resetPassword, data);
};

const me = async () => {
  return await api.get(endpointConfig.me);
};

const updatePassword = async (data: any) => {
  return await api.put(endpointConfig.updatePassword, data);
};

const updateInfo = async (data: any) => {
  return await api.put(endpointConfig.me, data);
};

const resendActivation = async ({ userId, token }: ICheckValidToken) => {
  return await api.post(endpointConfig.resendActivation, { userId, token });
};

const checkValidToken = async ({ userId, token }: ICheckValidToken) => {
  return await api.post(endpointConfig.verifyEmail + '/check-token', {
    userId,
    token,
  });
};

// eslint-disable-next-line import/no-anonymous-default-export
export const authApi = {
  signUp,
  signUpWithSocial,
  signIn,
  signInWithSocial,
  verifyEmail,
  forgotPassword,
  resetPassword,
  me,
  updatePassword,
  updateInfo,
  resendActivation,
  checkValidToken,
};
