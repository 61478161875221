import React from 'react';

export const Footer = (
  { isAuth }: { isAuth?: boolean } = { isAuth: false }
) => {
  return (
    <footer
      className={`${!isAuth ? 'fixed bottom-0' : 'static bottom-0'}  z-[999] flex w-full items-center justify-center border-t bg-white`}
    >
      <div className='main-app'>
        <div className='flex max-w-screen-2xl flex-row justify-center  py-4'>
          <p className='leading-5'>
            2024 © Copyright ESGLance. All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};
