import { Spin } from 'antd';
import React from 'react';

import { useAppSelector } from '@/store';

export const LoadingOverlay = () => {
  const { isLoading } = useAppSelector((state) => state.common);

  return <Spin spinning={isLoading} fullscreen size='large' />;
};
