'use client';

import { Layout, notification } from 'antd';
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';
import React from 'react';

import useCheckAuth from '@/hooks/useCheckAuth';

import { useAppDispatch, useAppSelector } from '@/store';
import { setIndustries } from '@/store/feature/common.slice';

import { authRoutes, Routes } from '@/helpers/common.constant';
import { useFullIndustries } from '@/queries/useIndustries';

import { Footer } from './Footer';
import { Header } from './Header';
import { LoadingOverlay } from '../LoadingOverlay';

const MainInfoDrawer = dynamic(() => import('../Drawer/MainInfoDrawer'), {
  ssr: false,
});

export const MainLayout = ({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) => {
  const { notification: nStore } = useAppSelector((state) => state.common);

  useCheckAuth();

  const [api, contextHolder] = notification.useNotification({
    maxCount: 3,
    placement: 'bottomRight',
  });

  const path = usePathname();

  const isAuth = authRoutes.includes(path as Routes);

  React.useEffect(() => {
    if (nStore.type) {
      api[nStore.type]({
        message: nStore.msgTitle,
        description: nStore.msgContent,
      });
    }
  }, [api, nStore]);

  const {
    query: { data },
  } = useFullIndustries();

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (data) {
      dispatch(setIndustries(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Layout className='bg-white'>
      {!isAuth && <Header />} <LoadingOverlay />
      {contextHolder}
      <main className='flex-center mb-10 mt-[70px] w-full bg-white'>
        <MainInfoDrawer />

        <div className='main-app'>{children}</div>
      </main>
      <Footer isAuth={isAuth} />
    </Layout>
  );
};
