import { MenuOutlined } from '@ant-design/icons';
import { Button, ConfigProvider, Drawer, Menu } from 'antd';
import Link from 'next/link';
import { useState } from 'react';

import useMenu from '@/hooks/useMenu';

const MenuMobile = () => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const menus = useMenu();

  return (
    <div className='xl:hidden'>
      <Button
        type='text'
        icon={<MenuOutlined />}
        onClick={() => {
          setOpen(true);
        }}
      />
      <Drawer
        placement={'left'}
        onClose={handleClose}
        open={open}
        styles={{ body: { padding: 0 } }}
      >
        <ConfigProvider
          theme={{
            components: {
              Menu: {
                /* here is your component tokens */
                itemBorderRadius: 0,
                itemMarginBlock: 0,
                itemMarginInline: 0,
              },
            },
          }}
        >
          <Menu
            selectable={false}
            // defaultOpenKeys={['sub1']}
            mode='inline'
            items={menus.map((me) => ({
              label: me.link ? (
                <Link href={me.link} onClick={handleClose}>
                  {me.label}
                </Link>
              ) : (
                me.label
              ),
              key: me.link,
              children: me.children?.map((subMe) => ({
                label: subMe.link ? (
                  <Link
                    className='text-nowrap'
                    href={subMe.link}
                    onClick={handleClose}
                  >
                    {subMe.label}
                  </Link>
                ) : (
                  subMe.label
                ),
                key: subMe.link,
              })),
            }))}
          />
        </ConfigProvider>
      </Drawer>
    </div>
  );
};

export default MenuMobile;
