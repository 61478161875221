'use client';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';

import { useAppSelector } from '@/store';

import { Roles } from '@/helpers/common.constant';

import NavSearch from '../NavSearch';
const NavSearchSection = () => {
  const { auth } = useAppSelector((state) => state.auth);

  return (
    <div className=''>
      {auth?.role !== Roles._SENECA_ADMIN && (
        <>
          <div className='hidden 2xl:block'>
            <NavSearch key={'nav-search-pc'} />
          </div>
          <Popover
            content={<NavSearch key={'nav-search-mobile'} />}
            className='2xl:hidden'
          >
            <Button
              type='text'
              icon={<SearchOutlined className='text-xl text-primary' />}
            />
          </Popover>
        </>
      )}
    </div>
  );
};
export default NavSearchSection;
