import { usePathname } from 'next/navigation';
import React from 'react';

import { useAppDispatch } from '@/store';
import { setAuth } from '@/store/feature/auth.slice';
import { setLoading } from '@/store/feature/common.slice';

import { authApi } from '@/app/auth/services/api.service';
import { CommonConstant } from '@/helpers/common.constant';
import { deleteCookie, setCookie } from '@/helpers/cookieStorage';
import { getLocalStorage } from '@/helpers/localStorage';

export default function useCheckAuth(): any {
  const dispatch = useAppDispatch();

  const path = usePathname();

  const getMe = async () => {
    try {
      const response = await authApi.me();

      if (response) {
        dispatch(setAuth(response.data));
      }
    } catch (error) {
      dispatch(setAuth(null));
    }
  };

  React.useEffect(() => {
    (async () => {
      dispatch(setLoading(true));

      const accessToken = await getLocalStorage(CommonConstant._ACCESS_TOKEN);

      if (accessToken) {
        getMe();
        setCookie(
          CommonConstant._ACCESS_TOKEN,
          accessToken,
          24 * 60 * 60 * 1000
        );
      } else {
        dispatch(setAuth(null));
        deleteCookie(CommonConstant._ACCESS_TOKEN);
      }
    })();

    dispatch(setLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);
}
