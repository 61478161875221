import { Avatar, Button, Dropdown, Space } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import Link from 'next/link';
import { signOut, useSession } from 'next-auth/react';
import React, { useMemo } from 'react';
import { BsChevronDown } from 'react-icons/bs';

import { useAppDispatch, useAppSelector } from '@/store';
import { clearAuth } from '@/store/feature/auth.slice';
import { setLoading } from '@/store/feature/common.slice';

import { CommonConstant, Roles, Routes } from '@/helpers/common.constant';
import { deleteCookie } from '@/helpers/cookieStorage';
import { deleteLocalStorage } from '@/helpers/localStorage';
import { generateAvatar, truncateString } from '@/helpers/utils';
import api from '@/services/api/api.service';

export const AuthSection = () => {
  const { auth } = useAppSelector((state) => state.auth);
  const session = useSession();

  const dispatch = useAppDispatch();

  const onLogout = async () => {
    dispatch(setLoading(true));

    if (session) {
      await signOut();
    }

    dispatch(clearAuth());

    deleteLocalStorage(CommonConstant._ACCESS_TOKEN);
    deleteLocalStorage(CommonConstant._REFRESH_TOKEN);
    deleteCookie(CommonConstant._ACCESS_TOKEN);

    delete api.defaults.headers.common['Authorization'];
    window.location.href = Routes._HOME;

    dispatch(setLoading(false));
  };

  const accountMenus: ItemType[] = useMemo(() => {
    switch (auth?.role) {
      case Roles._CORPORATE:
        return [
          {
            key: Routes._ORG_PROFILE,
            label: <Link href={Routes._ORG_PROFILE}>Profile Settings</Link>,
          },
          {
            key: Routes._ORG_ACCOUNT_SETTINGS,
            label: (
              <Link href={Routes._ORG_ACCOUNT_SETTINGS}>Account Settings</Link>
            ),
          },
        ];
      case Roles._CONSULTANT:
        return [
          {
            key: Routes._CON_PROFILE,
            label: <Link href={Routes._CON_PROFILE}>Profile Settings</Link>,
          },
          {
            key: Routes._CON_ACCOUNT_SETTINGS,
            label: (
              <Link href={Routes._CON_ACCOUNT_SETTINGS}>Account Settings</Link>
            ),
          },
        ];
      case Roles._SENECA_ADMIN:
        return [
          {
            key: Routes._ADMIN_ACCOUNT_SETTINGS,
            label: (
              <Link href={Routes._ADMIN_ACCOUNT_SETTINGS}>
                Account Settings
              </Link>
            ),
          },
        ];
      default:
        return [];
    }
  }, [auth?.role]);

  return auth?.role === Roles._GUEST || !auth ? (
    <div className='auth-btns flex flex-row gap-5'>
      <Link href={Routes._SIGN_IN} className='hidden xl:inline-block'>
        <Button size='large' className='bg-white px-10'>
          Sign in
        </Button>
      </Link>
      <Link href={Routes._SIGN_IN} className='inline-block xl:hidden'>
        <Button
          size='small'
          type='link'
          className='bg-white text-lg text-primary'
        >
          Sign in
        </Button>
      </Link>
      <Link href={Routes._SIGN_UP} className='hidden xl:inline-block'>
        <Button type='primary' size='large' className='px-10'>
          Sign up
        </Button>
      </Link>
    </div>
  ) : (
    <Dropdown
      menu={{
        items: [
          ...accountMenus,
          {
            key: '0',
            label: 'Logout',
            onClick: () => {
              onLogout();
            },
          },
        ],
      }}
      // trigger={['click']}
      placement='bottomRight'
    >
      <Space className='cursor-pointer'>
        <Avatar
          className='shadow '
          src={auth?.avatar ? auth?.avatar : generateAvatar(auth?.name)}
        />
        <Space className='hidden  text-nowrap lg:inline-flex'>
          {Boolean(auth) && truncateString(auth?.name)} |
          <span className='capitalize'>{auth?.role || Roles._CONSULTANT}</span>
          <BsChevronDown className='text-xs' />
        </Space>
      </Space>
    </Dropdown>
  );
};
