import { usePathname } from 'next/navigation';
import { ReactNode, useMemo } from 'react';

import { useAppSelector } from '@/store';

import { Roles, Routes } from '@/helpers/common.constant';
type AppMenuItem = {
  link?: any;
  label: ReactNode;
  children?: any[];
};
const useMenu = () => {
  const { auth } = useAppSelector((state) => state.auth);

  const currentPath = usePathname();
  const menuList: AppMenuItem[] = useMemo(() => {
    switch (auth?.role) {
      case Roles._SENECA_ADMIN:
        return [
          {
            label: 'Overview',
            link: Routes._ADMIN_OVERVIEW,
          },

          {
            label: 'Projects',
            link: Routes._ADMIN_ALL_JOB,
          },
          {
            label: 'Tenders',
            link: Routes._ADMIN_TENDER,
          },
          {
            label: 'Applications',
            link: Routes._ADMIN_APPLICATION,
          },
          {
            label: 'Consultants',
            link: Routes._ADMIN_CONSULTANT,
          },
          {
            label: 'Organizations',
            link: Routes._ADMIN_CORPORATE,
          },
          {
            label: 'Accounts',
            link: Routes._ADMIN_USER_ACCOUNT,
          },
        ];
      case Roles._CORPORATE:
        return [
          {
            label: 'Overview',
            link: Routes._ORG_OVERVIEW,
          },
          {
            label: 'Projects',
            children: [
              {
                label: 'Post Project',
                link: Routes._ORG_PROJECT_NEW_FLOW,
              },
              {
                label: 'All Projects',
                link: Routes._ORG_ALL_PROJECTS,
              },
            ],
          },
          {
            label: 'Applications',
            link: Routes._ORG_ALL_APPLICATIONS,
          },
          {
            label: 'Consultants',
            children: [
              {
                label: 'Find Consultants',
                link: Routes._FIND_CONSULTANT,
              },
              {
                label: 'Favorite Consultants',
                link: Routes._ORG_SAVED_CONSULTANT,
              },
            ],
          },
        ];
      case Roles._CONSULTANT:
        return [
          {
            label: 'Overview',
            link: Routes._CON_OVERVIEW,
          },
          {
            label: 'Find Jobs',
            link: Routes._FIND_WORK + '?typeSearch=tender',
          },
          {
            label: ' Applied Projects',
            link: Routes._CON_APPLIED_JOBS,
          },
          {
            label: 'Favorite Jobs',
            link: Routes._CON_FAVORITE_JOBS,
          },
        ];
      default:
        return [
          { label: 'Find Consultants', link: Routes._FIND_CONSULTANT },
          {
            label: 'Find Jobs',
            link: Routes._FIND_WORK + '?typeSearch=tender',
          },
        ];
    }
  }, [auth?.role, currentPath]);
  return menuList;
};

export default useMenu;
