import { Dropdown, Flex, Space } from 'antd';
import classNames from 'classnames';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import React from 'react';
import { BsChevronDown } from 'react-icons/bs';

import useMenu from '@/hooks/useMenu';

import { useAppSelector } from '@/store';

import { Roles } from '@/helpers/common.constant';

const MenuPC = () => {
  const { auth } = useAppSelector((state) => state.auth);
  const currentPath = usePathname();

  const menus = useMenu();
  const isActive = (path: string) => {
    switch (auth?.role) {
      case Roles._SENECA_ADMIN:
      case Roles._CORPORATE:
      case Roles._CONSULTANT:
      case Roles._GUEST:
      default:
        return currentPath !== '/' && path.startsWith(currentPath || '');
    }
  };

  return (
    <Flex gap={20} className='hidden xl:flex'>
      {menus.map((menu) => {
        if (menu.children) {
          return (
            <Dropdown
              key={menu.link}
              placement='bottom'
              menu={{
                style: { minWidth: 150 },
                items: menu.children.map((m: any) => ({
                  label: (
                    <Link href={m.link} key={m.link}>
                      {m.label}
                    </Link>
                  ),
                  key: m.link,
                })),
              }}
            >
              <a onClick={(e) => e.preventDefault()} className='px-2'>
                <Space className=' text-nowrap text-textBase'>
                  {menu.label}
                  <BsChevronDown className='text-xs' />
                </Space>
              </a>
            </Dropdown>
          );
        }
        return (
          <Link
            key={menu.link}
            href={menu.link}
            className={classNames(
              'text-nowrap px-2',
              isActive(menu.link)
                ? 'font-medium text-primary underline'
                : 'text-textBase'
            )}
          >
            {menu.label}
          </Link>
        );
      })}
    </Flex>
  );
};

export default MenuPC;
