import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import api from '@/services/api/api.service';
import endpointConfig from '@/services/api/endpoint.config';

const useIndustries = (calculateOption = true) => {
  const query = useQuery<any>({
    placeholderData: {
      industries: {
        GICS: [],
        SICS: [],
        TRBC: [],
      },
      region: [],
    },
    queryKey: ['industries'],
    queryFn: async () => {
      const { data } = await api.get(endpointConfig.industries);
      if (data) {
        return {
          industries: data.multi_industry,
          region: data.region,
        };
      }
      return new Error('Something went wrong');
    },
    staleTime: Infinity,
  });
  const {
    data: {
      industries: { GICS, SICS, TRBC },
    },
  } = query;
  const normalizeIndustryValue = (values: any) => {
    if (!values) {
      return null;
    }
    return values[values.length - 1];
  };

  const valByKey: any = useMemo(() => {
    const mapping: any = {};
    if (calculateOption) {
      [GICS, TRBC, SICS].forEach((type) => {
        type?.forEach((a: any) => {
          a.children.forEach((b: any) => {
            b.children.forEach((c: any) => {
              mapping[c.value] = [a.value, b.value, c.value];
            });
          });
        });
      });
    }

    return mapping;
  }, [GICS, TRBC, SICS, calculateOption]);

  const getValueIndustry = (val: any) => {
    return { value: valByKey[val] };
  };
  return { query, getValueIndustry, normalizeIndustryValue, valByKey };
};
export default useIndustries;

export const useFullIndustries = () => {
  const query = useQuery({
    queryKey: ['full-industries'],
    queryFn: async () => {
      const { data } = await api.get(endpointConfig.industries);
      if (data) {
        return data;
      }
      return new Error('Something went wrong');
    },
    staleTime: Infinity,
  });
  return { query };
};
