import { CloseCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Divider, Flex, Input, Select } from 'antd';
import { useRouter } from 'next/navigation';
import React, { useEffect, useState } from 'react';
import './style.css';

import useQuerySearch from '@/hooks/useQuerySearch';

import { useAppSelector } from '@/store';

import { Roles, Routes } from '@/helpers/common.constant';

const NavSearch = () => {
  const router = useRouter();
  const { auth } = useAppSelector((state) => state.auth);
  const { keyword } = useQuerySearch(['keyword']);
  const [searchKey, setSearchKey] = useState('');
  const [searchType, setSearchType] = useState('consultants');
  const handleSearch = () => {
    if (searchType === 'consultants') {
      router.push(`${Routes._FIND_CONSULTANT}?keyword=${searchKey}`);
    } else {
      if (auth?.role === Roles._CORPORATE) {
        router.push(`${Routes._ORG_ALL_PROJECTS}?search=${searchKey}`);
      } else {
        router.push(
          `${Routes._FIND_WORK}?typeSearch=project&keyword=${searchKey}`
        );
      }
    }
  };
  useEffect(() => {
    setSearchKey(keyword ?? '');
  }, [keyword]);
  return (
    <Flex align='center' className='rounded-full border-2 hover:bg-[#e9e9e9]'>
      <Input
        className='nav-search-input w-[250px]'
        variant='borderless'
        placeholder='Search'
        prefix={
          <SearchOutlined
            onClick={handleSearch}
            className='me-2 text-lg text-primary'
          />
        }
        suffix={
          searchKey && (
            <CloseCircleOutlined
              className='cursor-pointer'
              onClick={() => setSearchKey('')}
            />
          )
        }
        enterKeyHint='search'
        value={searchKey}
        onChange={(e) => setSearchKey(e.target.value)}
        onPressEnter={handleSearch}
      />
      <Divider type='vertical' />
      <Select
        className='rounded-full hover:bg-[#f9f9f9]'
        options={[
          { label: 'Consultants', value: 'consultants' },
          { label: 'Jobs', value: 'works' },
        ]}
        style={{ width: 120, height: 38 }}
        variant='borderless'
        value={searchType}
        onChange={(value) => setSearchType(value)}
      />
    </Flex>
  );
};

export default NavSearch;
